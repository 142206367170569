<template>
  <div id="sidenav-collapse-main" class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100">
    <ul class="navbar-nav">
      <li>
        <div class="profile">
          <select v-model="shop_seleted" class="form-select profile" :onclick='selectProfile()'
            :disabled="shopList.length === 0">
            <option value="0" disabled selected>{{ $t("content.amnselprofile") }}</option>
            <option v-for="(item, index) in shopList" :value="item" :key="index">
              {{ item.nameTh }} ( {{ item.nameEn }} )
            </option>
          </select>
          <!-- <select class="form-select profile">
            <option value="card0">{{ $t("content.amnselprofile") }}</option>
            <option value="card2">Profile A</option>
            <option value="card2">Profile B</option>
          </select> -->
        </div>
      </li>
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="userAccount" :nav-text="data.user" :nav-sub-text="data.role"
          :class="getRoute() === 'userAccount' ? 'active' : ''">
          <template #icon>
            <img src="../../assets/img/team-2.jpg" class="avatar" />
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(ac, setAccount) in account" :key="setAccount" :to="{ name: ac.path }"
                :mini-icon="ac.icon" :text="ac.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal light mt-2 mb-2" v-if="shop_seleted !== '0'" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''" v-if="shop_seleted !== '0'">
        <ul class="nav pe-0">
          <sidenav-item v-for="(ds, setMain) in dashboard" :key="setMain" :to="{ name: ds.path }" :mini-icon="ds.icon"
            :text="ds.name" />
        </ul>
      </li>

      <hr class="horizontal light mt-2 mb-2" v-if="shop_seleted !== '0'" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''" v-if="shop_seleted !== '0'">
        <sidenav-collapse collapse-ref="dashboardsExamples" :nav-text="t('apps.general')"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">business_center</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(gn, setGeneral) in general" :key="setGeneral" :to="{ name: gn.path }"
                :mini-icon="gn.icon" :text="gn.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <!-- 
      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="financialsExamples" :nav-text="t('apps.financial')"
          :class="getRoute() === 'financial' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">payments</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(fn, setFinancial) in financial" :key="setFinancial" :to="{ name: fn.path }"
                :mini-icon="fn.icon" :text="fn.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->
      <!-- 
      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="customersExamples" :nav-text="t('apps.customer')"
          :class="getRoute() === 'customer' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">support_agent</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(cm, setCustomer) in customer" :key="setCustomer" :to="{ name: cm.path }"
                :mini-icon="cm.icon" :text="cm.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->

      <hr class="horizontal light mt-2 mb-2" v-if="shop_seleted !== '0'" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''" v-if="shop_seleted !== '0'">
        <sidenav-collapse collapse-ref="stocksExamples" :nav-text="t('apps.stocks')"
          :class="getRoute() === 'stock' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">inventory_2</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(st, setStock) in stock" :key="setStock" :to="{ name: st.path }" :mini-icon="st.icon"
                :text="st.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <!-- 
      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="reportsExamples" :nav-text="t('apps.report')"
          :class="getRoute() === 'report' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">summarize</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(rp, setReport) in report" :key="setReport" :to="{ name: rp.path }"
                :mini-icon="rp.icon" :text="rp.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->

      <hr class="horizontal light mt-2 mb-2" v-if="shop_seleted !== '0'" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''" v-if="shop_seleted !== '0'">
        <sidenav-collapse collapse-ref="masterExamples" :nav-text="t('apps.master')"
          :class="getRoute() === 'master' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">view_list</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(ms, setMaster) in master" :key="setMaster" :to="{ name: ms.path }"
                :mini-icon="ms.icon" :text="ms.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal light mt-2 mb-2" v-if="shop_seleted !== '0'" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''" v-if="shop_seleted !== '0'">
        <sidenav-collapse collapse-ref="settingExamples" :nav-text="t('apps.setting')"
          :class="getRoute() === 'settings' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">settings</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(menu3, setMenu) in setting" :key="setMenu" :to="{ name: menu3.path }"
                :mini-icon="menu3.icon" :text="menu3.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, computed, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";

const { t } = useI18n();

const route = useRoute();




const shopList = ref([]);
const shop_seleted = ref();



const loadDataList = async () => {
  if (localStorage.getItem("shoplist") !== null) {
    shopList.value = JSON.parse(localStorage.getItem("shoplist"));
    // console.log("shopList side : ", shopList.value);
  }
  if (localStorage.getItem("shop_seleted") !== null) {
    shop_seleted.value = JSON.parse(localStorage.getItem("shop_seleted"));
  } else {
    shop_seleted.value = '0'
  }
};

loadDataList();


async function selectProfile() {
  if (window.location.href.indexOf("signin") == -1) {
    if (shop_seleted.value !== null && shop_seleted.value !== '0') {
      // console.log("selectProfile side : " + JSON.stringify(shop_seleted.value));
      if (localStorage.getItem("shop_seleted") !== null) {
        const old_shop = JSON.parse(localStorage.getItem("shop_seleted"));
        if (shop_seleted.value.uid !== old_shop.uid) {
          reloadPage()
        }
      } else {
        reloadPage()
      }

    }
  }
}

async function reloadPage() {

  // The last "domLoading" Time //

  var currentDocumentTimestamp =

    new Date(performance.timing.domLoading).getTime();

  // Current Time //

  var now = Date.now();

  // Ten Seconds //

  var twoSec = 2 * 1000;

  // Plus Two Seconds //

  var plusTwoSec = currentDocumentTimestamp + twoSec;

  if (now > plusTwoSec) {
    const lf = filterData(shop_seleted.value.uid, shopList.value)
    // console.log("Profile side : " + JSON.stringify(lf[0]));
    await localStorage.setItem("shop_seleted", JSON.stringify(lf[0]));
    location.reload();
  } else {
    console.log("alredy loaded");
    // shop_seleted.value = JSON.parse(localStorage.getItem("shop_seleted"));
  }

}


function filterData(str, matchArr) {
  return matchArr.filter((c) => c.uid === str);
}






const data = reactive({
  user: "Name Lastname",
  role: "Administrator",
});



const account = reactive([
  // {
  //   path: "Profile Overview",
  //   icon: "face",
  //   name: computed(() => t("apps.acprofile")),
  // },
  {
    path: "Settings",
    icon: "settings",
    name: computed(() => t("apps.acsetting")),
  },
  {
    path: "Signin Basic",
    icon: "logout",
    name: computed(() => t("apps.aclogout")),
  },
]);

const dashboard = reactive([
  {
    path: "Dashboard",
    icon: "dashboard",
    name: computed(() => t("apps.gdashboard")),
  },
]);

const general = reactive([
  // {
  //   path: "TransactionList",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.glisttrans")),
  // },
  {
    path: "TransactionOverall",
    icon: "chevron_right",
    name: computed(() => t("apps.goverall")),
  },
  {
    path: "Jor6",
    icon: "chevron_right",
    name: computed(() => t("apps.gjor6")),
  },

  //   {
  //     path: "SplitAssets",
  //     icon: "chevron_right",
  //     name: computed(() => t("apps.gsplitasset")),
  //   },
  //   {
  //     path: "History",
  //     icon: "chevron_right",
  //     name: computed(() => t("apps.ghistory")),
  //   },
  //   {
  //     path: "TicketChain",
  //     icon: "chevron_right",
  //     name: computed(() => t("apps.gticketchain")),
  //   },
  //   {
  //     path: "DeferItems",
  //     icon: "chevron_right",
  //     name: computed(() => t("apps.gdeferitems")),
  //   },
  //   {
  //     path: "Seize",
  //     icon: "chevron_right",
  //     name: computed(() => t("apps.seize")),
  //   },
  //   {
  //     path: "UnitOverdue",
  //     icon: "chevron_right",
  //     name: computed(() => t("apps.gunitover")),
  //   },
]);

// const financial = reactive([
//   {
//     path: "Finance",
//     icon: "chevron_right",
//     name: computed(() => t("apps.gfinance")),
//   },
//   {
//     path: "CashMovement",
//     icon: "chevron_right",
//     name: computed(() => t("apps.gcashmove")),
//   },
//   {
//     path: "TranctionsManagement",
//     icon: "chevron_right",
//     name: computed(() => t("apps.gtrans")),
//   },
//   {
//     path: "CashDrawer",
//     icon: "chevron_right",
//     name: computed(() => t("apps.cashdrawer")),
//   },
// ]);

// const customer = reactive([
//   {
//     path: "CustomerInfo",
//     icon: "chevron_right",
//     name: computed(() => t("apps.gcustomer")),
//   },
//   {
//     path: "CustomerPort",
//     icon: "chevron_right",
//     name: computed(() => t("apps.gport")),
//   },
//   {
//     path: "CustomerDeposit",
//     icon: "chevron_right",
//     name: computed(() => t("apps.gdeposit")),
//   },
// ]);

const stock = reactive([
  {
    path: "Location",
    icon: "chevron_right",
    name: computed(() => t("apps.location")),
  },
  // {
  //   path: "Stock",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.stock")),
  // },
]);

// const report = reactive([
//   {
//     path: "ReportAll",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportall")),
//   },
//   {
//     path: "ReportDay",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportday")),
//   },
//   {
//     path: "ReportPawn",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportpawn")),
//   },
//   {
//     path: "ReportpProfit",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportprofit")),
//   },
//   {
//     path: "ReportAddCap",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportaddcap")),
//   },
//   {
//     path: "ReportReduceCap",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportreducecap")),
//   },
//   {
//     path: "ReportRedeem",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportredeem")),
//   },
//   {
//     path: "ReportCancel",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportcancel")),
//   },
//   {
//     path: "ReportDefer",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportdefer")),
//   },
//   {
//     path: "ReportTop20",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reporttop")),
//   },
//   {
//     path: "ReportTransaction",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportpay")),
//   },
//   {
//     path: "ExportOverdueItems",
//     icon: "chevron_right",
//     name: computed(() => t("apps.exportitemoverdue")),
//   },
// ]);

const master = reactive([
  // {
  //   path: "Bank",
  //   icon: "chevron_right",
  //   name: computed(() => t('apps.mbank'))
  // },
  {
    path: "Warning",
    icon: "chevron_right",
    name: computed(() => t("apps.mwarning")),
  },
  {
    path: "IllegalAssets",
    icon: "chevron_right",
    name: computed(() => t("apps.millegal")),
  },
  // {
  //   path: "AssetGroup",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.massetgroup")),
  // },
  {
    path: "AssetType",
    icon: "chevron_right",
    name: computed(() => t("apps.mtypeassets")),
  },
  // {
  //   path: "Prefix",
  //   icon: "chevron_right",
  //   name: computed(() => t('apps.mprefix'))
  // },
  // {
  //   path: "Address",
  //   icon: "chevron_right",
  //   name: computed(() => t('apps.maddress'))
  // },
  // {
  //   path: "CustomerGrade",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.mtypecustomer")),
  // },
  // {
  //   path: "DocumentType",
  //   icon: "chevron_right",
  //   name: computed(() => t('apps.mtypedocument'))
  // },
  // {
  //   path: "Units",
  //   icon: "chevron_right",
  //   name: computed(() => t('apps.mtypeunit'))
  // },
  // {
  //   path: "PawnBank",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.mpawn")),
  // },
  // {
  //   path: "CashAccount",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.mcash")),
  // },
  // {
  //   path: "FinancialTransactionType",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.mtrans")),
  // },
  // {
  //   path: "CategoriesOrder",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.catorder")),
  // },
  // {
  //   path: "BankNote",
  //   icon: "chevron_right",
  //   name: computed(() => t('apps.mbanknote'))
  // },
  // {
  //   path: "BusinessType",
  //   icon: "chevron_right",
  //   name: computed(() => t('apps.mbiz'))
  // }
]);

const setting = reactive([
  // {
  //   path: "OpenBranch",
  //   icon: "chevron_right",
  //   name: computed(() => t('apps.sopen'))
  // },
  // {
  //   path: "SetBranch",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.ssetbranch")),
  // },
  // {
  //   path: "PrePrint",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.spre")),
  // },
  // {
  //   path: "Interest",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.sinterest")),
  // },
  // {
  //   path: "SetStock",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.sstock")),
  // },
  // {
  //   path: "GoldPrice",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.sgold")),
  // },
  {
    path: "Holidays",
    icon: "chevron_right",
    name: computed(() => t("apps.mholiday")),
  },
  // {
  //   path: "SecurityRole",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.srole")),
  // },
  // {
  //   path: "SecuritySetup",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.ssetup")),
  // },
  // {
  //   path: "SetUser",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.suser")),
  // },
]);

function getRoute() {
  const routeArr = route.path.split("/");
  return routeArr[1];
}



</script>
