/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useJ6Store = defineStore("j6", {
  actions: {
    async j6ListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          shopUid: payload.shopUid,
          year: payload.year,
          month: payload.month, // month start from 0 to 11
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/j6/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async j6InfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/pawn-svc/j6/info/" + payload.shopUid + "/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async j6AddFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
        shopInfo1: payload.shopInfo1,
        shopInfo2: payload.shopInfo2,
        startDate: payload.startDate,
        endDate: payload.endDate,
        period: payload.period,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/j6/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async j6UdpateResponseFetch(payload) {
      const body = {
        uid: payload.uid,
        responseDate: payload.responseDate,
        breakingDate: payload.breakingDate,
        durationToSale: payload.durationToSale,
        saleDate: payload.saleDate,
        version: payload.version,
        shopUid: payload.shopUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/j6/update/response";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async j6DeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/pawn-svc/j6/delete/" + payload.shopUid + "/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
