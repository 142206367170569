<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <ul id="j6Tab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="first-j6" class="nav-link active" data-bs-toggle="tab" data-bs-target="#firstJ6" type="button"
              role="tab" aria-controls="firstJ6" aria-selected="true">
              {{ $t("content.amnjor6") }}
            </button>
          </li>
        </ul>

        <div id="j6TabContent" class="tab-content">
          <div id="firstJ6" class="tab-pane fade show active" role="tabpanel" aria-labelledby="first-finance">
            <div class="card h-100 mt-2">
              <div class="card-header mb-0">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="d-inline-block mb-0 me-4">{{ $t("content.amnjor6") }}</h5>
                  <div>
                    <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="setadd">
                      <i class="material-icons material-symbols-outlined">add</i>
                      {{ btnAddNew }}
                    </material-button>
                    <material-button id="addModal" color="primary" class="float-lg-start float-sm-end mb-0"
                      data-bs-toggle="modal" data-bs-target="#newJor6FormModal" v-show="false">
                    </material-button>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.year") }}</label>
                    <select v-model="years_search" class="form-select">
                      <option value="" disabled selected>{{ $t('content.select') }}</option>
                      <option v-for="(a, sel2) in listyear" :key="sel2" :value="a">
                        {{ a }}
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.month") }}</label>
                    <select v-model="months_search" class="form-select">
                      <option value="" disabled selected>{{ $t('content.select') }}</option>
                      <option v-for="(a, sel3) in datas.optMonths" :key="sel3" :value="a.value">
                        {{ a.items }}
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                      :disabled="years_search === '' || months_search === ''" @click="loadDataApi">
                      <i class="material-icons material-symbols-outlined">search</i>
                      {{ btnSearch }}
                    </material-button>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.amnunovperiod") }}</th>
                          <th>{{ $t("content.amnunovname") }}</th>
                          <th>{{ $t("content.amnallticket") }}</th>
                          <th>{{ $t("content.amnallitem") }}</th>
                          <th>{{ $t("content.allprice") }}</th>
                          <th>{{ $t("content.amnlastmaker") }}</th>
                          <th>{{ $t("content.status") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listdata" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ format_date(row.startDate) }} - {{ format_date(row.endDate) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.period }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString(row.numOfWorkCase) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString(row.totalQuantity) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.totalPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.lastUser }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.responseDate !== null && row.breakingDate !== null">
                              {{ $t("content.amnstateending") }}
                            </span>
                            <span v-else>
                              {{ $t("content.amnstateacting") }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="bottom" title="PDF" @click="pdfGen(row)">
                              <i class="material-icons material-symbols-outlined">picture_as_pdf</i>
                            </material-button>
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="bottom" title="Excel" @click="XlsxGen(row)">
                              <i class="material-icons material-symbols-outlined">description</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle"
                              :title="$t('buttons.edit')" @click="setupdate(row)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button id="updateModal" color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="modal" data-bs-target="#editJor6FormModal" v-show="false">
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              :title="$t('buttons.dele')"
                              :disabled="row.responseDate !== null && row.breakingDate !== null"
                              @click="deleteRowItems(row)">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                            <material-button color="default" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="bottom" :title="btnPrint"
                              :disabled="row.responseDate === null || row.breakingDate === null"
                              @click="stickerGen(row)">
                              <i class="material-icons material-symbols-outlined">print</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="listdata.length === 0">
                          <td colspan="8" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="9" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listdata.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                          @click="onClickHandler" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Add New Dialog -->
        <div id="newJor6FormModal" class="modal fade" tabindex="-1" aria-labelledby="newJor6FormModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.amnjor6add") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-3">
                      <label class="form-label ms-0">{{ $t("content.year") }}</label>
                      <select v-model="j6_detail.years" class="form-select" @change="set_period">
                        <option value="" disabled selected>{{ $t('content.select') }}</option>
                        <option v-for="(item, index) in listyearadd" :value="item.items" :key="index">
                          {{ item.items }}
                        </option>
                      </select>
                    </div>

                    <div class="col-lg-3">
                      <label class="form-label ms-0">{{ $t("content.month") }}</label>
                      <select v-model="j6_detail.months" class="form-select" @change="set_period"
                        :disabled="j6_detail.years === ''">
                        <option value="" disabled selected>{{ $t('content.select') }}</option>
                        <option v-for="(a, sel3) in datas.optMonths" :key="sel3" :value="a.value"
                          :disabled="monthDiff(a.value) < 4">
                          {{ a.items }}
                        </option>
                      </select>
                    </div>

                    <div class="col-lg-3">
                      <label class="form-label ms-0">{{ $t("content.amnunovperiod") }}</label>
                      <select v-model="j6_detail.durations" class="form-select" @change="set_period">
                        <option value="" disabled selected>{{ $t('content.select') }}</option>
                        <option v-for="(a, pr1) in datas.demo1" :key="pr1" :value="a.val">
                          {{ a.items }}
                        </option>
                      </select>
                    </div>

                    <div class="col-lg-3">
                      <label class="form-label ms-0">{{
                        $t("content.amnunovname")
                      }}</label>
                      <material-input id="over_name" type="text" v-model="j6_detail.period" disabled />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-end">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  :disabled="j6_detail.years === '' || j6_detail.months === '' || j6_detail.durations === ''"
                  @click="addJ6">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Edit Confirmation Dialog -->
        <div id="editJor6FormModal" class="modal fade" tabindex="-1" aria-labelledby="editJor6FormModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.amnjor6edit") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-3">
                      <label class="form-label ms-0">{{
                        $t("content.amnjor6conf")
                      }}</label>
                      <!-- <material-input id="confirmDate" type="date" /> -->
                      <VueDatePicker id="responseDate" v-model="j6_detail.responseDate" :format="format" auto-apply
                        :teleport="true" :enable-time-picker="false" month-name-format="long" :clearable="false" fluid
                        :text-input="textInputOptions" :max-date="new Date()" @update:model-value="add_date" />
                    </div>

                    <div class="col-lg-3">
                      <label class="form-label ms-0">{{ $t("content.amnjor6dtad") }}</label>
                      <InputNumber id="distanceDate" v-model="j6_detail.durationToSale" autocomplete="off"
                        @focus="$event.target.select()" fluid size="small" @update:modelValue="add_date" :min="0" />
                      <!-- <material-input type="text" /> -->
                    </div>

                    <div class="col-lg-3">
                      <label class="form-label ms-0">{{ $t("content.amnjor6lost") }}</label>
                      <!-- <material-input id="lostDate" type="date" disabled="true" /> -->
                      <VueDatePicker id="breakingDate" v-model="j6_detail.breakingDate" :format="format" auto-apply
                        :teleport="true" :enable-time-picker="false" month-name-format="long" :clearable="false" fluid
                        disabled />
                    </div>

                    <div class="col-lg-3">
                      <label class="form-label ms-0">{{ $t("content.amnjor6dist") }}</label>
                      <!-- <material-input id="distributeDate" type="date" disabled="true" /> -->
                      <VueDatePicker id="saleDate" v-model="j6_detail.saleDate" :format="format" auto-apply
                        :teleport="true" :enable-time-picker="false" month-name-format="long" :clearable="false" fluid
                        disabled />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-end">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  :disabled="j6_detail.responseDate === null || j6_detail.responseDate === '' || j6_detail.durationToSale === null || j6_detail.durationToSale === ''
                    || j6_detail.breakingDate === null || j6_detail.breakingDate === '' || j6_detail.saleDate === null || j6_detail.saleDate === ''" @click="updateJ6">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, computed, ref, inject } from "vue";
import { useI18n } from "vue-i18n";

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import moment from 'moment'
import ExampleFive from "@/examples/ExampleOne";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import InputNumber from 'primevue/inputnumber';
import axios from "axios";
import * as st from "@/config/setting.js";

import { useJ6Store } from '@/stores/j6'
import { useShopLicenseStore } from '@/stores/shoplicense'

const storej = useJ6Store()
const storesh = useShopLicenseStore()

const { t } = useI18n();
const swal = inject("$swal");

const currentYear = new Date().getFullYear();
const years = computed(() =>
  Array.from({ length: 51 }, (_, i) => currentYear + 543 - 50 + i)
);

// const months = computed(() => Array.from({ length: 12 }, (_, i) => i));
const selectedYear = ref(currentYear + 543);


const datas = reactive({
  searchBox1: "",
  searchBox2: "",
  years: "",
  months: "",
  optMonths: [
    { items: computed(() => t("opts.mon1")), value: '01' },
    { items: computed(() => t("opts.mon2")), value: '02' },
    { items: computed(() => t("opts.mon3")), value: '03' },
    { items: computed(() => t("opts.mon4")), value: '04' },
    { items: computed(() => t("opts.mon5")), value: '05' },
    { items: computed(() => t("opts.mon6")), value: '06' },
    { items: computed(() => t("opts.mon7")), value: '07' },
    { items: computed(() => t("opts.mon8")), value: '08' },
    { items: computed(() => t("opts.mon9")), value: '09' },
    { items: computed(() => t("opts.mon10")), value: '10' },
    { items: computed(() => t("opts.mon11")), value: '11' },
    { items: computed(() => t("opts.mon12")), value: '12' },
  ],
  demo1: [
    { items: computed(() => "01 - 10"), val: '1' },
    { items: computed(() => "11 - 20 "), val: '2' },
    { items: computed(() => "21 - " + t("content.endmonth")), val: '3' },
  ],
  demo2: [
    { items: computed(() => "xx/67 " + "#1") },
    { items: computed(() => "xx/67 " + "#2") },
    { items: computed(() => "xx/67 " + "#3") },
    { items: computed(() => "xx/67 " + "#4") },
  ],
});

///// computed
const cancel = computed(() => t("buttons.canc"));
const confirm = computed(() => t("buttons.conf"));
const save = computed(() => t("buttons.save"));
const btnSearch = computed(() => t("buttons.sear"));
const btnPrint = computed(() => t("buttons.printSticker"));
const btnAddNew = computed(() => t("content.amnaddnew"));

const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val);
};

const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const formatyear = (date) => {
  return moment(String(date)).local().format('YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const listyear = computed(() =>
  Array.from({ length: 51 }, (_, i) => formatyear(new Date(new Date().setFullYear(new Date().getFullYear() - i))))
);


const listyearadd = ref([
  { items: formatyear(new Date(new Date().setFullYear(new Date().getFullYear() - 1))) },
  { items: formatyear(new Date(new Date().setFullYear(new Date().getFullYear() - 0))) },
]);

const years_search = ref('');
const months_search = ref('');


const listdata = ref([]);

const j6_detail = ref({
  uid: '',
  shopUid: '',
  shopInfo1: '',
  shopInfo2: '',
  startDate: null,
  endDate: null,
  responseDate: null,
  breakingDate: null,
  durationToSale: null,
  saleDate: null,
  version: '',
  durations: '',
  months: '',
  years: '',
  period: ''
})

const shop_detail = ref(null);

const key = JSON.parse(localStorage.getItem("shop_seleted"));

const onClickHandler = (page) => {
  updatePagination()
};


const infoshop = async (val) => {
  let data = "";
  shop_detail.value = null
  data = {
    index: "0",
    size: "1",
    shopUid: key.uid,
    validYear: formatyear(new Date(new Date().setFullYear(new Date().getFullYear() - 0)))
  };
  const payload = data;
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storesh.shopLicenseListFetch(payload);
  // console.log('info res : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        shop_detail.value = response.data.data.list[0]
        // console.log('shoplicense: ' + JSON.stringify(shop_detail.value));
      }
    }
  }
}

infoshop()

const loadDataApi = async (val) => {
  let data = "";
  if (currentPage.value !== 1) {
    if (listdata.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    shopUid: key.uid,
    year: years_search.value,
    month: Number(months_search.value) - 1
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storej.j6ListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listdata.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}



async function updatePagination() {
  listdata.value = []
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    shopUid: key.uid,
    year: years_search.value,
    month: Number(months_search.value) - 1
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storej.j6ListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listdata.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}




function set_period() {
  let pd = ''
  if (j6_detail.value.months === '01') {
    pd = 'ม.ค.'
  } else if (j6_detail.value.months === '02') {
    pd = 'ก.พ.'
  } else if (j6_detail.value.months === '03') {
    pd = 'มี.ค.'
  } else if (j6_detail.value.months === '04') {
    pd = 'เม.ย.'
  } else if (j6_detail.value.months === '05') {
    pd = 'พ.ค.'
  } else if (j6_detail.value.months === '06') {
    pd = 'มิ.ย.'
  } else if (j6_detail.value.months === '07') {
    pd = 'ก.ค.'
  } else if (j6_detail.value.months === '08') {
    pd = 'ส.ค.'
  } else if (j6_detail.value.months === '09') {
    pd = 'ก.ย.'
  } else if (j6_detail.value.months === '10') {
    pd = 'ต.ค.'
  } else if (j6_detail.value.months === '11') {
    pd = 'พ.ย.'
  } else if (j6_detail.value.months === '12') {
    pd = 'ธ.ค.'
  }

  if (j6_detail.value.years !== '') {
    pd = pd + ' ปี ' + String(j6_detail.value.years).slice(-2)
  }

  if (j6_detail.value.durations !== '') {
    pd = pd + ' งวดที่ ' + j6_detail.value.durations
  }
  j6_detail.value.period = pd
}


function monthDiff(months) {
  let dateTo, dateFrom
  dateTo = new Date(new Date(new Date().getFullYear()), new Date().getMonth())
  dateFrom = new Date(new Date(new Date(j6_detail.value.years).getFullYear()), new Date(months).getMonth())
  return dateTo.getMonth() - dateFrom.getMonth() +
    (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}


function add_date() {
  j6_detail.value.breakingDate = null
  j6_detail.value.saleDate = null
  if (j6_detail.value.responseDate !== null) {
    var d = new Date(j6_detail.value.responseDate);
    var x = Number(30);
    d.setDate(d.getDate() + x);
    j6_detail.value.breakingDate = d
    var d2 = new Date(j6_detail.value.breakingDate);
    var x2 = Number(j6_detail.value.durationToSale);
    d2.setDate(d.getDate() + x2);
    j6_detail.value.saleDate = d2

  }
}


function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function setadd() {
  clearItems()
  document.getElementById("addModal").click();
}

function setupdate(item) {
  clearItems()
  j6_detail.value = {
    uid: item.uid,
    shopUid: item.shopUid,
    shopInfo1: item.shopInfo1,
    shopInfo2: item.shopInfo2,
    startDate: item.startDate,
    endDate: item.endDate,
    responseDate: item.responseDate,
    breakingDate: item.breakingDate,
    durationToSale: item.durationToSale,
    saleDate: item.saleDate,
    version: item.version,
    durations: '',
    months: '',
    years: '',
    period: item.period
  }
  if (j6_detail.value.responseDate !== null) {
    j6_detail.value.responseDate = new Date(j6_detail.value.responseDate)
  }
  if (j6_detail.value.breakingDate !== null) {
    j6_detail.value.breakingDate = new Date(j6_detail.value.breakingDate)
  }
  if (j6_detail.value.saleDate !== null) {
    j6_detail.value.saleDate = new Date(j6_detail.value.saleDate)
  }
  document.getElementById("updateModal").click();
  // infoj6(item.uid)
}

function clearItems() {
  j6_detail.value = {
    uid: '',
    shopUid: '',
    shopInfo1: '',
    shopInfo2: '',
    startDate: null,
    endDate: null,
    responseDate: null,
    breakingDate: null,
    durationToSale: null,
    saleDate: null,
    version: '',
    durations: '',
    months: '',
    years: '',
    period: ''
  }
}


function getLstDayOfMonFnc(date) {
  return new Date(date.getFullYear(), date.getMonth(), 0).getDate()
}

async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deletej6(item.uid)
      }
    }
  });
}

async function addJ6() {
  const payload = j6_detail.value;
  payload.shopUid = key.uid
  payload.shopInfo1 = key.fullname
  let licenseBookNo = ''
  let licenseNo = ''
  let address = ''
  if (shop_detail.value.licenseBookNo !== null) {
    licenseBookNo = shop_detail.value.licenseBookNo
  }
  if (shop_detail.value.licenseNo !== null) {
    licenseNo = shop_detail.value.licenseNo
  }
  if (key.address !== null) {
    address = key.address
  }
  payload.shopInfo2 = 'ใบอนุญาต เล่มที่ ' + licenseBookNo + ' เลขที่ ' + licenseNo + ' ตั้งอยู่ ' + address
  let year = Number(payload.years)
  if (payload.durations === '1') {
    payload.startDate = year + '-' + payload.months + '-01'
    payload.endDate = year + '-' + payload.months + '-10'
  } else if (payload.durations === '2') {
    payload.startDate = year + '-' + payload.months + '-11'
    payload.endDate = year + '-' + payload.months + '-20'
  } else if (payload.durations === '3') {
    payload.startDate = year + '-' + payload.months + '-21'
    let endd = getLstDayOfMonFnc(new Date(year, Number(payload.months), 21))
    payload.endDate = year + '-' + payload.months + '-' + endd
  }
  // console.log('add payload : ' + JSON.stringify(payload));
  const response = await storej.j6AddFetch(payload);
  // console.log('add res : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        clearItems()
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('add')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc + ' ' + response.data.data, "error");
      }
    }
  }
}

async function updateJ6() {
  const payload = j6_detail.value;
  payload.responseDate = moment(new Date(payload.responseDate)).local().format('YYYY-MM-DD')
  payload.breakingDate = moment(new Date(payload.breakingDate)).local().format('YYYY-MM-DD')
  payload.saleDate = moment(new Date(payload.saleDate)).local().format('YYYY-MM-DD')
  // console.log('update payload : ' + JSON.stringify(payload));
  const response = await storej.j6UdpateResponseFetch(payload);
  // console.log('update res : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        clearItems()
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('update')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }

}


async function deletej6(id) {
  let data = "";
  data = {
    uid: id,
    shopUid: key.uid
  };
  const payload = data;
  // console.log('del payload : ' + JSON.stringify(payload));
  const response = await storej.j6DeleteFetch(payload);
  // console.log('del res : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function infoj6(id) {
  let data = "";
  data = {
    uid: id,
    shopUid: key.uid
  };
  const payload = data;
  const response = await storej.j6InfoFetch(payload);
  // console.log('info res : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log('info res : ' + JSON.stringify(response.data));
      }
    }
  }
}


async function pdfGen(item) {
  const keya = JSON.parse(localStorage.getItem("FR-SDK-nvtx-admin"));
  const accessToken = keya.accessToken;
  const response = await axios.get(st.url_api + `/docgen-svc/j6/` + item.shopUid + '/' + item.uid, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer'
  });

  if (response) {
    // console.log('blob - response ', response);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    // console.log('url : ' + url)
    window.open(url, '_blank').focus();
  } else {
    swal("โหลด PDF ไม่สำเร็จ", "", "error");
  }
}


async function XlsxGen(item) {
  const keya = JSON.parse(localStorage.getItem("FR-SDK-nvtx-admin"));
  const accessToken = keya.accessToken;
  const response = await axios.get(st.url_api + `/docgen-svc/j6/xlsx/` + item.shopUid + '/' + item.uid, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer'
  });

  if (response) {
    // console.log('blob - response ', response);
    let today = moment(new Date()).local().format('YYYY-MM-DD')
    let fileName = key.code + `-${today}.xlsx`
    const url = URL.createObjectURL(new Blob([response.data], { type: "application/xlsx" }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  } else {
    swal("โหลด EXCEL ไม่สำเร็จ", "", "error");
  }
}


async function stickerGen(item) {
  const keya = JSON.parse(localStorage.getItem("FR-SDK-nvtx-admin"));
  const accessToken = keya.accessToken;
  const response = await axios.get(st.url_api + `/docgen-svc/j6/sticker/` + item.shopUid + '/' + item.uid, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer'
  });

  if (response) {
    // console.log('blob - response ', response);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    // console.log('url : ' + url)
    window.open(url, '_blank').focus();
  } else {
    swal("โหลดสติ๊กเกอร์ ไม่สำเร็จ", "", "error");
  }
}





</script>

<style lang="scss" scoped></style>
