/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useShopCatOrderStore = defineStore("shopcatorder", {
  actions: {
    async shopCatOrderListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          shopUid: payload.shopUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-catorder/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopCatOrderInfoFromUidFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-catorder/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopCatOrderInfoFromSessionFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-catorder/info" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopCatOrderAddFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
        catOrders: payload.catOrders,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-catorder/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopCatOrderUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        shopUid: payload.shopUid,
        catOrders: payload.catOrders,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-catorder/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopCatOrderDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-catorder/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
