/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useShopLicenseStore = defineStore("shoplicense", {
  actions: {
    async shopLicenseListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          shopUid: payload.shopUid,
          validYear: payload.validYear,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-license/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopLicenseInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-license/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopLicenseAddFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
        validYear: payload.validYear,
        licenseBookNo: payload.licenseBookNo,
        licenseNo: payload.licenseNo,
        allowShopName: payload.allowShopName,
        licenseOwner: payload.licenseOwner,
        fromDate: payload.fromDate,
        endDate: payload.endDate,
        refDocUid: payload.refDocUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-license/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopLicenseUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        shopUid: payload.shopUid,
        validYear: payload.validYear,
        licenseBookNo: payload.licenseBookNo,
        licenseNo: payload.licenseNo,
        allowShopName: payload.allowShopName,
        licenseOwner: payload.licenseOwner,
        shopAddress: payload.shopAddress,
        fromDate: payload.fromDate,
        endDate: payload.endDate,
        refDocUid: payload.refDocUid,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-license/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopLicenseDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-license/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
